import { Text } from "@chakra-ui/react";
import CustomModal from "components/CustomModal";

const HowToPlayModal = ({ isOpen, onClose, memeTokenInfo }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} title="how to play">
      <Text fontSize={"xs"} color="white">
        To play, simply choose the number of Keys you want, pick your team, and
        click the "Send {memeTokenInfo?.symbol} to Buy" button.
      </Text>
      <Text fontSize={"xs"} color="white" mt={6}>
        Congratulations—you now hold the key to the pot! If no one else buys a
        Key, you win it all!
      </Text>
      <Text
        color="brand"
        fontSize={"md"}
        fontWeight={"medium"}
        textTransform={"capitalize"}
        textAlign={"left"}
        w="100%"
        mt={6}
      >
        Want to spread your joy and earn 10% affiliate fees?
      </Text>
      <Text color="b07" fontSize={"xs"} mt={2}>
        Just register a name for{" "}
        <Text as="span" color="brand">
          0.01 BNB
        </Text>{" "}
        under the Referrals tab. Once registered, you’ll receive a custom
        Referral link, like{" "}
        <Text as="span" textDecorationLine={"underline"} color="#0077FA">
          fomome.me/inventor.
        </Text>{" "}
        Anytime someone buys Keys through your link, you’ll earn 10% of their
        purchase!
        <br />
        <br /> Bonus: if you’ve registered a name and you’re the most recent Key
        buyer, your name will appear at the top!
      </Text>
    </CustomModal>
  );
};

export default HowToPlayModal;
