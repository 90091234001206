import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@rainbow-me/rainbowkit/styles.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import {
  RainbowKitProvider,
  connectorsForWallets,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { WagmiProvider, createConfig, fallback, http } from "wagmi";
import { bscTestnet, bsc } from "@wagmi/core/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  metaMaskWallet,
  walletConnectWallet,
  coinbaseWallet,
  okxWallet,
} from "@rainbow-me/rainbowkit/wallets";

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet, walletConnectWallet, coinbaseWallet, okxWallet],
    },
  ],
  {
    appName: "Fomo3dMeme",
    projectId: "66c86bbf1f685f883b54aee9f287ffea",
  }
);

export const config = createConfig({
  chains: [bsc, bscTestnet],
  transports: {
    [bsc.id]: fallback([
      http("https://rpc.ankr.com/bsc", {
        batch: {
          wait: 800,
        },
      }),
    ]),
    [bscTestnet.id]: fallback([
      http("https://data-seed-prebsc-1-s1.bnbchain.org:8545", {
        batch: {
          wait: 800,
        },
      }),
    ]),
  },
  connectors,
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider locale="en" theme={darkTheme()}>
        <ChakraProvider
          theme={theme}
          toastOptions={{
            defaultOptions: { position: "top-right" },
          }}
        >
          <App />
        </ChakraProvider>
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
