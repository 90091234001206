import { Flex, Box, Text, Image, VStack, Button, Icon } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useAccount, useBalance, useChainId, useReadContracts } from "wagmi";
import stakingContractAbi from "abis/abi-staking.json";
import { getContract } from "config/contracts";
import { getMemeTokenInfo, getStakingToken } from "config/tokens";
import { formatAmount } from "utils/numbers";
import { useEffect, useState, useCallback } from "react";
import Marquee from "react-fast-marquee";
import fomoLogo from "assets/fomo.svg";
import bnbChainLogo from "assets/bnb-chain-logo.svg";
import { borders } from "theme";
import { CustomInput } from "components/Input";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { parseEther } from "viem";
import emptyIcon from "assets/not-connect.png";
import TokenAbi from "abis/token.json";
import useCustomWriteContract from "hooks/useCustomWriteContract";
import rightArrowImg from "assets/right-arrow.svg";
import { maxUint256 } from "config/common";
import { formatPrice, useTokenPrices } from "utils/price";

interface RewardData {
  earned: BigNumber;
  price: BigNumber;
  duration: BigNumber;
  rewardRate: BigNumber;
  decimal: number;
}

const StakingPage = () => {
  const chainId = useChainId();
  const { address, isConnected } = useAccount();
  const account = address?.toLowerCase();
  const [isStaking, setIsStaking] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const { openConnectModal } = useConnectModal();

  const rewardTokens = getStakingToken(chainId, "rewards");
  const priceTable = useTokenPrices(chainId, rewardTokens);
  const getPrice = useCallback((symbol: string) => priceTable[symbol.toLowerCase()] || 0, [priceTable]);

  const stakingContractInfo = {
    address: getContract(chainId, "staking"),
    abi: stakingContractAbi,
    chainId: chainId,
  };

  const stakeTokenInfo = getStakingToken(chainId, "stake");

  const { data: balanceData } = useBalance({
    address: account as `0x${string}`,
    chainId,
    token: stakeTokenInfo?.address,
    query: {
      refetchInterval: 1500,
    },
  });

  const userBalance = balanceData?.value;

  const contractsArg = [
    {
      abi: TokenAbi,
      address: stakeTokenInfo?.address,
      functionName: "allowance",
      args: [address, getContract(chainId, "staking")],
      chainId: chainId,
    },
    {
      ...stakingContractInfo,
      functionName: "balanceOf",
      args: [account],
    },
    {
      ...stakingContractInfo,
      functionName: "totalSupply",
    },
  ];
  rewardTokens.forEach(rewardToken => {
    const token = getMemeTokenInfo(chainId, rewardToken);
    const callArgs = [
      {
        ...stakingContractInfo,
        functionName: "earned",
        args: [account, token.address],
      },
      {
        ...stakingContractInfo,
        functionName: "rewardData",
        args: [token.address],
      }
    ]
    contractsArg.push(...callArgs);
  });

  const { data, status, fetchStatus } = useReadContracts({
    contracts: contractsArg,
    query: {
      refetchInterval: 1000,
    },
  });

  const [stakingData, setStakingData] = useState<{
    totalStaked: BigNumber;
    staked: BigNumber;
    rewards: RewardData[];
    totalRewardsUSD: BigNumber;
  }>({
    totalStaked: BigNumber(0),
    staked: BigNumber(0),
    totalRewardsUSD: BigNumber(0),
    rewards: rewardTokens.map(() => ({
      earned: BigNumber(0),
      price: BigNumber(0),
      duration: BigNumber(0),
      rewardRate: BigNumber(0),
      decimal: 1,
    })),
  });
  const [apy, setApy] = useState(BigNumber(0));
  useEffect(() => {
    if (!data) return;
    const [, stakedRes, totalStakedRes] = data || [];
    const rewards: RewardData[] = [];
    rewardTokens.forEach((token, index) => {
      const earned = BigNumber(data[index*2 + 3]?.result as BigNumber || 0) || BigNumber(0);
      const rewardData = (data[index*2 + 4]?.result as any[]) || [];
      const price = BigNumber(getPrice(token));
      const [, duration, , rewardRate] = rewardData;
      rewards.push({
        earned,
        price: price,
        duration: BigNumber(duration),
        rewardRate: BigNumber(rewardRate),
        decimal: getMemeTokenInfo(chainId, token)?.decimal || 1,
      });
    });

    const totalStaked = BigNumber(totalStakedRes?.result as BigNumber) || BigNumber(0);
    const staked = (stakedRes?.result as BigNumber) || BigNumber(0);
    const totalRewardsUSD = rewards.reduce((acc, reward) => acc.plus(reward.earned.div(10**reward.decimal).multipliedBy(reward.price)), BigNumber(0));

    setStakingData({
      totalStaked,
      staked,
      rewards,
      totalRewardsUSD,
    });
  }, [getPrice, rewardTokens,address, chainId, data]);

  useEffect(() => {
    if (stakingData.rewards.length === 0) return;
    if (stakingData.totalStaked.isZero()) return;

    // rewardsRate * duration/86400 * rewardTokenPrice / (totalSupply * stakingTokenPrice)
    // apy = (1 + yieldRatePerDay) ^ 365 - 1

    let yieldPerDay = BigNumber(0);
    stakingData.rewards.forEach(reward => {
      if (reward.duration.isNaN() || reward.rewardRate.isNaN() || reward.price.isNaN()) return;
      yieldPerDay = yieldPerDay.plus(
        reward.rewardRate
        .multipliedBy(reward.duration.div(86400))
        .multipliedBy(reward.price).div(10**reward.decimal)
      );
    });
    const yieldRatePerDay = yieldPerDay.div(
      stakingData.totalStaked.div(10 ** stakeTokenInfo.decimal).multipliedBy(getPrice(stakeTokenInfo.symbol))
    );
    // apy = (1 + yieldRatePerDay) ^ 365 - 1
    const _apy = yieldRatePerDay.plus(1).pow(365).minus(1).multipliedBy(100);
    setApy(_apy);
  }, [stakingData]);

  const [needApproval, setNeedApproval] = useState(false);
  useEffect(() => {
    if (isStaking) {
      setNeedApproval(
        !!inputVal &&
          (parseEther(inputVal) as BigInt) > (data?.[0]?.result as BigInt)
      );
    } else {
      setNeedApproval(false);
    }
  }, [data, inputVal, isStaking]);


  const handleMax = () => {
    setInputVal(
      `${
        formatAmount(
          isStaking ? userBalance : stakingData?.staked,
          18,
          4,
          0
        ) as number
      }`
    );
  };

  const triggerToken = useCustomWriteContract(
    isStaking ? `Staked successfully!` : `Unstaked successfully!`,
    (isSuccess) => {
      if (isSuccess) setInputVal("");
    }
  );
  const writeApproveContract = useCustomWriteContract(
    `Successfully approved!`,
    () => setIsApproving(false)
  );
  const handleStake = () => {
    if (!isConnected) return openConnectModal?.();
    if (isApproving) return;
    if (needApproval) {
      setIsApproving(true);
      // @ts-ignore
      writeApproveContract({
        abi: TokenAbi,
        address: stakeTokenInfo.address,
        functionName: "approve",
        args: [getContract(chainId, "staking"), maxUint256],
      });
      return;
    }
    triggerToken({
      ...stakingContractInfo,
      functionName: isStaking ? "stake" : "withdraw",
      args: [parseEther(inputVal)],
    });
  };

  const withdrawContract = useCustomWriteContract("Withdraw Successfully");
  const handleWithdraw = () => {
    withdrawContract({
      ...stakingContractInfo,
      functionName: "getReward",
    });
  };

  const isInsufficient =
    inputVal >
    formatAmount(
      isStaking ? userBalance : stakingData?.staked,
      18,
      8,
      0,
      false,
      true
    );
  const buttonText = (() => {
    if (!isConnected) return "Connect Wallet";
    if (!+inputVal) return "Enter an amount";
    if (isInsufficient) return "Insufficient balance";
    if (isApproving) return "Approving...";
    if (needApproval) return `Approve $${stakeTokenInfo?.symbol}`;
    return isStaking ? "Confirm Stake" : "Confirm Unstake";
  })();

  // if (status === "pending") {
  //   return <div>Staking</div>;
  // }

  const gotoBuyFomo = () =>
    window.open(
      "https://pancakeswap.finance/swap?outputCurrency=0x8CEE53fbb76fC419857Fdc50a813bF834543C44F",
      "_blank"
    );

  return (
    <Flex w="100%" flexDirection="column" h="100%" flex="1">
      <Box w="100vw" overflow={"hidden"} h={{ lg: 10, base: 6 }}>
        <Marquee speed={100}>
          <Flex
            gap={"10px"}
            bg="b02"
            alignItems={"center"}
            h={{ lg: 10, base: 6 }}
            w="max-content"
          >
            {[
              `Staking $${stakeTokenInfo?.symbol} can get real-time dividends and pool dividends`,
              `Staking $${stakeTokenInfo?.symbol} can get real-time dividends and pool dividends`,
              `Staking $${stakeTokenInfo?.symbol} can get real-time dividends and pool dividends`,
              `Staking $${stakeTokenInfo?.symbol} can get real-time dividends and pool dividends`,
              `Staking $${stakeTokenInfo?.symbol} can get real-time dividends and pool dividends`,
            ].map((text, index) => {
              return (
                <Flex gap={"10px"} alignItems={"center"} key={index}>
                  <Icon
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 0L12.2029 5.54933C12.3271 8.94439 15.0149 11.6672 18.3648 11.7912L24 12L18.3648 12.2087C15.0149 12.3328 12.3271 15.0556 12.2029 18.4507L12 24L11.7971 18.4507C11.6729 15.0556 8.98508 12.3328 5.63523 12.2087L0 12L5.63522 11.7912C8.98508 11.6672 11.6729 8.94439 11.7971 5.54933L12 0Z"
                      fill="#FCD535"
                    />
                  </Icon>
                  <Text fontWeight={"normal"} color="brand" fontSize={"xs"}>
                    {text}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Marquee>
      </Box>
      <Box
        w="100%"
        px={{ lg: 10, md: "22px", base: "10px" }}
        py={{ md: 6, base: "10px" }}
      >
        <Box maxW="1360px" mx="auto" pos="relative">
          <Flex
            gap={6}
            mt={{ lg: "1.375rem", base: "10px" }}
            direction={{ lg: "row", md: "row", base: "column" }}
            justifyContent="center"
            color="white"
          >
            <Box
              bg="#141414"
              flex="1"
              pos="relative"
              p={5}
              borderRadius={"4px"}
              border="1px solid rgba(252, 213, 53, 0.10)"
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25);"}
              h="fit-content"
            >
              {/* Staking Info */}
              <VStack spacing={5} alignItems={"flex-start"}>
                <Flex justifyContent={"space-between"} w="100%">
                  <Flex gap={"13px"}>
                    <Box pos="relative">
                      <Image src={fomoLogo} boxSize={12} />
                      <Image
                        src={bnbChainLogo}
                        boxSize={5}
                        pos="absolute"
                        left="33px"
                        top="28px"
                      />
                    </Box>
                    <VStack spacing={0} alignItems={"flex-start"}>
                      <Text
                        color="white"
                        fontSize={"20px"}
                        fontWeight={"semibold"}
                        letterSpacing={"0.6px"}
                      >
                        ${stakeTokenInfo?.symbol}
                      </Text>
                      <Text
                        color="b05"
                        fontSize={"md"}
                        lineHeight={"24px"}
                        fontFamily={"Inter"}
                      >
                        Token
                      </Text>
                    </VStack>
                  </Flex>
                  <Button
                    variant={"brand"}
                    h={8}
                    fontSize={{ md: "md" }}
                    border="none"
                    borderRadius={"2px"}
                    onClick={gotoBuyFomo}
                  >
                    Get ${stakeTokenInfo?.symbol}
                    <Icon
                      width="10px"
                      height="10px"
                      viewBox="0 0 10 10"
                      fill="none"
                      ml={2}
                    >
                      <path
                        d="M1.54514 10L0 8.45486L6.26736 2.1875H0.868056V0H10V9.13194H7.8125V3.73264L1.54514 10Z"
                        fill="black"
                      />
                    </Icon>
                  </Button>
                </Flex>
                <Flex
                  direction={{ lg: "row", md: "column", base: "column" }}
                  justifyContent={"space-between"}
                  w="100%"
                >
                  <Flex gap={1}>
                    <Text color="#10A957" fontSize={"40px"} fontWeight={"bold"}>
                      {formatAmount(apy, 0, 2)}%
                    </Text>
                    <Text mt="25px">APY(Est.)</Text>
                  </Flex>
                  <Flex
                    gap={"48px"}
                    justifyContent={{ lg: "flex-end", md: "space-between" }}
                  >
                    <Flex direction={"column"}>
                      <Text color="b05" fontSize={"sm"} fontFamily={"Inter"}>
                        Total ${stakeTokenInfo?.symbol} staked
                      </Text>
                      <Text
                        fontSize={"lg"}
                        fontWeight={"medium"}
                        letterSpacing={"0.6px"}
                        lineHeight={"32px"}
                      >
                        {formatAmount(stakingData.totalStaked, 18, 4, 0, true)}
                      </Text>
                      <Text fontSize={"11px"} color="b07">
                        ≈$
                        {(
                          getPrice(stakeTokenInfo.symbol) *
                          (formatAmount(
                            stakingData.totalStaked,
                            18,
                            6,
                            0,
                            false,
                            true
                          ) as number)
                        ).toFixed(2)}
                      </Text>
                    </Flex>
                    <Flex direction={"column"}>
                      <Text color="b05" fontSize={"sm"} fontFamily={"Inter"}>
                        Price
                      </Text>
                      <Text
                        fontSize={"lg"}
                        fontWeight={"medium"}
                        letterSpacing={"0.6px"}
                        lineHeight={"32px"}
                      >
                        ${formatPrice(getPrice(stakeTokenInfo.symbol), 5)}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Box h="1px" w="100%" bg="b02"></Box>
                <Flex direction={"column"} gap={2} fontSize={"sm"} w="100%">
                  <Text color="white" fontWeight={"medium"}>
                    About ${stakeTokenInfo?.symbol} Staking
                  </Text>
                  <Text color="b07">
                    ${stakeTokenInfo?.symbol} staking allows users to stake
                    their ${stakeTokenInfo?.symbol} and boost their returns
                    based on staking and FomoMe ecosystem activity. Staking $
                    {stakeTokenInfo?.symbol} can get real-time dividends and
                    pool dividends. Learn about ${stakeTokenInfo?.symbol}{" "}
                    staking boosts{" "}
                    <Text
                      as="span"
                      color="brand"
                      textDecoration={"underline"}
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://fomome.gitbook.io/fomome/usdfomo/tokenomics",
                          "_blank"
                        )
                      }
                    >
                      here.
                    </Text>
                  </Text>
                </Flex>
              </VStack>
            </Box>

            <Flex direction={"column"} gap={6} flex="1">
              {/* Staking Actions */}
              <Flex
                direction={"column"}
                gap={4}
                bg="#141414"
                pos="relative"
                p={5}
                pt={2}
                borderRadius={"4px"}
                border="1px solid rgba(252, 213, 53, 0.10)"
                boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25);"}
              >
                <Flex gap={4}>
                  <Box
                    py={2}
                    color={isStaking ? "white" : "b07"}
                    borderBottom={isStaking ? borders.thickBrand : "none"}
                    cursor={"pointer"}
                    onClick={() => setIsStaking(true)}
                  >
                    Stake
                  </Box>
                  <Box
                    py={2}
                    color={isStaking ? "b07" : "white"}
                    borderBottom={isStaking ? "none" : borders.thickBrand}
                    cursor={"pointer"}
                    onClick={() => setIsStaking(false)}
                  >
                    Unstake
                  </Box>
                </Flex>
                <Box p={4} bg="black" borderRadius={"2px"}>
                  <Flex w="100%" alignItems={"center"}>
                    <CustomInput
                      h="42px"
                      borderRadius={"none"}
                      _focus={{ border: "none" }}
                      color="white"
                      fontSize={"28px"}
                      inputProps={{
                        value: inputVal,
                        onChange: (v) => {
                          setInputVal(v);
                        },
                      }}
                      placeholder="0.0"
                      flex={1}
                      sx={{
                        caretColor: "#FCD535",
                      }}
                      ml="-5px"
                      _placeholder={{
                        color: "b05",
                      }}
                    />
                    {isConnected && (
                      <Button
                        ml={2}
                        variant={"outline"}
                        h={6}
                        onClick={handleMax}
                      >
                        MAX
                      </Button>
                    )}
                  </Flex>
                  <Flex color="b05" fontSize={"xs"}>
                    <Box flex="1">
                      ${(getPrice(stakeTokenInfo.symbol) * (+inputVal || 0)).toFixed(2)}
                    </Box>
                    {isConnected && (
                      <Flex flex="1" justifyContent={"flex-end"} gap={1}>
                        {isStaking ? "Balance" : "Active"}:{" "}
                        {formatAmount(
                          isStaking ? userBalance : stakingData?.staked,
                          18,
                          4,
                          0,
                          true
                        )}
                        {isStaking && +inputVal && isInsufficient && (
                          <>
                            <Box w="1px" h="18px" bg="#333"></Box>

                            <Text
                              color="brand"
                              fontSize={"xs"}
                              cursor={"pointer"}
                              onClick={gotoBuyFomo}
                            >
                              Add fund
                            </Text>
                          </>
                        )}
                      </Flex>
                    )}
                  </Flex>
                </Box>

                <Button
                  w="100%"
                  variant={"brand"}
                  h={10}
                  isDisabled={
                    !+inputVal || (isConnected && isInsufficient) || isApproving
                  }
                  onClick={handleStake}
                  fontSize={"md"}
                  fontFamily={"Rubik"}
                  border="none"
                  borderRadius={"2px"}
                >
                  {buttonText}
                </Button>

                {stakingData?.staked && isConnected ? (
                  <VStack spacing={1}>
                    <Flex
                      justifyContent={"space-between"}
                      w="100%"
                      fontSize={"sm"}
                    >
                      <Text color={"b05"}>Your FOMO Staked</Text>
                      <Text color="white">
                        {formatAmount(stakingData.staked, 18, 4, 0, true)} FOMO
                      </Text>
                    </Flex>
                    <Flex
                      w="100%"
                      justifyContent={"flex-end"}
                      color="b05"
                      fontSize={"xs"}
                    >
                      $
                      {(
                        (formatAmount(
                          stakingData.staked,
                          18,
                          4,
                          0,
                          false,
                          true
                        ) as number) * (getPrice(stakeTokenInfo.symbol) || 0)
                      ).toFixed(2)}
                    </Flex>
                    <Flex
                      justifyContent={"space-between"}
                      w="100%"
                      fontSize={"sm"}
                    >
                      <Text color={"b05"}>Est.APY</Text>
                      <Text color="#01C86A">{formatAmount(apy, 0, 2)}%</Text>
                    </Flex>
                  </VStack>
                ) : (
                  <Text color="b05" fontSize={"sm"}>
                    Stake your $FOMO to maximize your Staking reward,{" "}
                    <Text color="#10A957" as="span">
                      Up to {formatAmount(apy, 0, 2)}% APY
                    </Text>
                  </Text>
                )}
              </Flex>

              {/* Vault */}
              <Box
                bg="#141414"
                flex="1"
                pos="relative"
                borderBottomRadius={"4px"}
                borderTopRadius={"2px"}
                boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25);"}
              >
                {isConnected ? (
                  <>
                    <Box
                      bg="brand"
                      w="100%"
                      h="28px"
                      pl={5}
                      borderTopRadius={"2px"}
                    >
                      <Text
                        fontWeight={"semibold"}
                        fontSize={"sm"}
                        color="black"
                        lineHeight={"28px"}
                      >
                        Rewards
                      </Text>
                    </Box>
                    <VStack
                      spacing={4}
                      p={5}
                      bg="linear-gradient(0deg, rgba(252, 213, 53, 0.05) 0%, rgba(252, 213, 53, 0.05) 100%), #0F0F0F;"
                      w="100%"
                    >
                      <VStack
                        spacing={4}
                        w="100%"
                        borderBottom={"1px solid #333"}
                      >
                        {rewardTokens.map((rewardInfo, index) => (
                          <VStack spacing={1} w="100%" pb={2} key={index}>
                            <Flex
                              flex="1"
                              justifyContent={"space-between"}
                              w="100%"
                            >
                              <Text color="b07" fontSize={"sm"}>
                                {getMemeTokenInfo(chainId, rewardInfo)?.symbol}
                              </Text>
                              <Text fontSize={"15px"}>
                                {formatAmount(
                                  stakingData.rewards[index].earned,
                                  0,
                                  getMemeTokenInfo(chainId, rewardInfo)?.decimal,
                                  4,
                                  0,
                                  true
                                )}{" "}
                                {getMemeTokenInfo(chainId, rewardInfo)?.symbol}
                              </Text>
                            </Flex>
                            <Flex justifyContent={"flex-end"} w="100%">
                              <Text color="b05" fontSize={"xs"}>
                                {" "}
                                ≈ ${formatPrice(getPrice(rewardInfo), 5)}
                              </Text>
                            </Flex>
                          </VStack>
                        ))}
                      </VStack>
                      <Flex flex="1" justifyContent={"space-between"} w="100%">
                        <Text color="b07" fontSize={"sm"}>
                          Total Rewards
                        </Text>
                        <Text fontSize={"15px"}>≈ ${stakingData.totalRewardsUSD.toFixed(2)} USD</Text>
                      </Flex>
                      <Button
                        variant={"outline"}
                        w="100%"
                        fontSize={"md"}
                        onClick={handleWithdraw}
                      >
                        Withdraw
                        <Icon
                          width="17px"
                          height="16px"
                          viewBox="0 0 17 16"
                          fill="none"
                          ml={2}
                        >
                          <g clipPath="url(#clip0_10809_6493)">
                            <mask
                              id="mask0_10809_6493"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="17"
                              height="16"
                            >
                              <rect
                                x="0.5"
                                width="16"
                                height="16"
                                fill="#D9D9D9"
                              />
                            </mask>
                            <g mask="url(#mask0_10809_6493)">
                              <path
                                d="M4.67109 14.8569C4.16633 14.8569 3.74014 14.6831 3.39252 14.3354C3.0449 13.9878 2.87109 13.5616 2.87109 13.0569V7.74258C2.87109 7.23782 3.0449 6.81163 3.39252 6.46401C3.74014 6.11639 4.16633 5.94258 4.67109 5.94258H5.88538V7.74258H4.67109V13.0569H12.3282V7.74258H11.114V5.94258H12.3282C12.833 5.94258 13.2592 6.11639 13.6068 6.46401C13.9544 6.81163 14.1282 7.23782 14.1282 7.74258V13.0569C14.1282 13.5616 13.9544 13.9878 13.6068 14.3354C13.2592 14.6831 12.833 14.8569 12.3282 14.8569H4.67109ZM7.59967 10.7854V4.57115L6.68538 5.48544L5.42824 4.22829L8.49967 1.14258L11.5711 4.22829L10.314 5.48544L9.39967 4.57115V10.7854H7.59967Z"
                                fill="#FCD535"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_10809_6493">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                          </defs>
                        </Icon>
                      </Button>
                    </VStack>
                  </>
                ) : (
                  <Flex
                    w="100%"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderRadius={"4px"}
                    p={5}
                    pr="27px"
                  >
                    <Box w="100%">
                      <Text fontSize={"sm"} fontWeight={"medium"}>
                        Games Vault
                      </Text>
                      <Text
                        mt="18px"
                        fontSize={{ md: "2xl", base: "xl" }}
                        color={"brand"}
                        fontFamily={"Rubik"}
                        maxW={{ md: "unset", base: "173px" }}
                      >
                        Connect wallet Check your profit！
                      </Text>
                      <Button
                        variant={"brand"}
                        mt={{ md: 2, base: 3 }}
                        h={{ md: 8, base: 6 }}
                        fontSize={{ md: "md", base: "xs" }}
                        onClick={() => openConnectModal?.()}
                        border="none"
                        borderRadius={"2px"}
                      >
                        Connect
                        <Image
                          src={rightArrowImg}
                          ml={2}
                          boxSize={{ md: 3, base: 2 }}
                        />
                      </Button>
                    </Box>
                    <Image src={emptyIcon} w={"205px"} h={"88px"} />
                  </Flex>
                )}
              </Box>
            </Flex>
          </Flex>

          {/* Market Information */}
          {/* <Box mt={10}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Market Information
            </Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Token</Th>
                  <Th>Price</Th>
                  <Th>Staking APY (estimate)</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Flex alignItems="center">
                      <Image
                        src="/path-to-fomo-icon.png"
                        alt="FOMO"
                        boxSize="24px"
                        mr={2}
                      />
                      FOMO
                    </Flex>
                  </Td>
                  <Td>$123.0464</Td>
                  <Td>188.88%</Td>
                  <Td>
                    <Button size="sm">Get ${stakeTokenInfo?.symbol}</Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box> */}

          {/* Transactions */}
          {/* <Box mt={10}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Your Transactions
            </Text>
            {address ? (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Action</Th>
                    <Th>Date</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody></Tbody>
              </Table>
            ) : (
              <Text>Connect your wallet to see your staking history</Text>
            )}
          </Box> */}
        </Box>
      </Box>
    </Flex>
  );
};

export default StakingPage;

// returns amounts of BNB to get 1 token
function getPriceInBNB(
  sqrtPriceX96: any,
  bnbAddress: string,
  token0Address: string,
  token1Address: string
) {
  const sqrtPrice = BigNumber(sqrtPriceX96).div(2 ** 96);
  const price = sqrtPrice.pow(2);

  if (token0Address?.toLowerCase() === bnbAddress?.toLowerCase()) {
    return BigNumber(1).div(price);
  } else if (token1Address?.toLowerCase() === bnbAddress?.toLowerCase()) {
    return price;
  } else {
    console.error("BNB is neither token0 nor token1");
    return BigNumber(0);
  }
}

function callResToPriceInBNB(bnbAddress, resArray) {
  if (!Array.isArray(resArray) || resArray.length !== 3) {
    throw new Error("resArray must be an array with exactly 3 elements");
  }

  const [sqrtPriceX96Res, token0AddressRes, token1AddressRes] = resArray;
  const [sqrtPriceX96, , , , , ,] = (sqrtPriceX96Res?.result as any[]) || [];
  const token0Address = token0AddressRes?.result as string;
  const token1Address = token1AddressRes?.result as string;
  return getPriceInBNB(sqrtPriceX96, bnbAddress, token0Address, token1Address);
}
