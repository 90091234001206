import { bsc, bscTestnet } from "viem/chains";

export const MAIN_TOKEN = {
  [bscTestnet.id]: [
    {
      address: "0x9984CEb3542Ab2B64700ED5F4C4579D1E8195e4e",
      symbol: "FOMO",
      decimal: 18,
      nativeTokenAddr: "0xee786a1aa32fc164cca9a28f763fbc835e748129",
      nativeTokenDecimal: 8,
      bnbV3PoolAddr: ""
    },
    {
      address: "0xe6ADF425D11cEe361F2B61D9612134D85b8A775d",
      symbol: "TFB",
      decimal: 18,
      nativeTokenAddr: "0xee786a1aa32fc164cca9a28f763fbc835e748129",
      nativeTokenDecimal: 8,
      bnbV3PoolAddr: ""
    },
    {
      address: "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
      symbol: "BUSD",
      decimal: 18,
      nativeTokenAddr: "0xee786a1aa32fc164cca9a28f763fbc835e748129",
      nativeTokenDecimal: 8,
      bnbV3PoolAddr: ""
    },
    {
      address: "0xc2d7a4a403FAf7bb0DFfBfBf6c6629F7c0380430",
      symbol: "BABYDOGE",
      decimal: 9,
      nativeTokenAddr: "0xee786a1aa32fc164cca9a28f763fbc835e748129",
      nativeTokenDecimal: 8,
      bnbV3PoolAddr: ""
    },
  ],
  [bsc.id]: [
    {
      address: "0x8CEE53fbb76fC419857Fdc50a813bF834543C44F",
      symbol: "FOMO",
      decimal: 18,
      bnbV3PoolAddr: "0x95C4C2a218c6658CaFe5DE1DF9088cd18297aa37"
    },
    {
      address: "0x9eC02756A559700d8D9e79ECe56809f7bcC5dC27",
      symbol: "WHY",
      decimal: 18,
      nativeTokenAddr: "0x242a1ff6ee06f2131b7924cacb74c7f9e3a5edc9",
      nativeTokenDecimal: 18,
      bnbV3PoolAddr: "0x6CD061334aaFeE6CDD5bC5C958e71f769D7cA8CC"
    },
    {
      address: "0xc1FC087902aE8D7fc1Bc972b5B59C722ff188bc5",
      symbol: "EXO",
      decimal: 18,
      nativeTokenAddr: "0x242a1ff6ee06f2131b7924cacb74c7f9e3a5edc9",
      nativeTokenDecimal: 18,
      bnbV3PoolAddr: ""
    },
    {
      address: "0xc748673057861a797275CD8A068AbB95A902e8de",
      symbol: "BABYDOGE",
      decimal: 9,
      nativeTokenAddr: "0x242a1ff6ee06f2131b7924cacb74c7f9e3a5edc9",
      nativeTokenDecimal: 18,
      bnbV3PoolAddr: "0x61db764C20a2EBfB7e8a7a5AFb0b2Dd85A4CEF5f"
    },
  ],
};

export const STAKING_TOKEN = {
  [bscTestnet.id]: {
    stake: {
      address: "0x9984CEb3542Ab2B64700ED5F4C4579D1E8195e4e",
      symbol: "FOMO",
      decimal: 18,
    },
    rewards: ["FOMO", "TFB", "BUSD", "BABYDOGE"],
  },
  [bsc.id]: {
    stake: {
      address: "0x8CEE53fbb76fC419857Fdc50a813bF834543C44F",
      symbol: "FOMO",
      decimal: 18,
    },
    rewards: ["FOMO", "WHY", "BABYDOGE"],
  },
};

export const getMemeTokenInfo = (chainId, token = "WHY") => {
  const tokenList = MAIN_TOKEN[chainId] || MAIN_TOKEN[bsc.id];
  return tokenList.find(
    ({ symbol }) => symbol.toLowerCase() === token.toLowerCase()
  );
};

export const getStakingToken = (chainId: number, name: string) => {
  return STAKING_TOKEN[chainId]?.[name] || STAKING_TOKEN[bsc.id][name];
};
