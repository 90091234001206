import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { borders } from "theme";
import { formatAmount, numberWithCommas } from "utils/numbers";
import { getUSDPrice } from "utils/util";

const RoundTabPanel = ({
  CommonCountdown,
  isDuringRound,
  isDuringGapTime,
  curRoundNum,
  keysOwned,
  winingsVault,
  curPot,
  tokenPrice,
  generalVault,
  affiliateVault,
  memeTokenInfo,
  roundTotalKeys,
  isWaitingWinner,
  tokenDecimal,
}) => {
  const total =
    (affiliateVault || BigInt(0)) +
    (winingsVault || BigInt(0)) +
    (generalVault || BigInt(0));
  const InfoItem = ({
    title,
    num,
    value = "",
    totalText = "",
    token = "",
    symbol = "",
  }: {
    title: string;
    num: string | number;
    value?: string | number;
    totalText?: string | number;
    token?: any;
    symbol?: string;
  }) => {
    return (
      <Flex w="100%" justifyContent={"space-between"}>
        <Text fontSize={{ md: "lg", base: "sm" }} fontWeight={"semibold"}>
          {title}
        </Text>
        <VStack alignItems={"flex-end"}>
          <HStack
            spacing={2}
            fontSize={{ md: "lg", base: "sm" }}
            fontWeight={"bold"}
          >
            <VStack spacing={"0"} alignItems={"flex-end"}>
              <Text>{num}</Text>
              <HStack spacing={2}>
                {totalText ? <Text>{totalText}</Text> : null}
                {(value === 0 || !!value) && (
                  <Text fontSize={{ md: "md", base: "sm" }}>≈ {value}</Text>
                )}
              </HStack>
            </VStack>
            {/* TODO: USD */}
            <VStack spacing={"0"} alignItems={"flex-end"}>
              <Text>{token}</Text>
              {!!symbol && (
                <Text fontSize={{ md: "md", base: "sm" }}>{symbol}</Text>
              )}
            </VStack>
          </HStack>
        </VStack>
      </Flex>
    );
  };
  return (
    <VStack spacing={{ md: 6, base: "10px" }} w="100%">
      <Box w="100%">
        <Center
          bg="black"
          h={"1.75rem"}
          fontSize={{ md: "md", base: "xs" }}
          color="white"
          w="max-content"
          px={6}
        >
          Round #
          {curRoundNum === 1
            ? curRoundNum
            : isDuringRound || isDuringGapTime || isWaitingWinner
            ? curRoundNum
            : curRoundNum + 1}
        </Center>
        <Center
          gap={{ md: 3, base: 1 }}
          h={{ md: "6.25rem", base: "unset" }}
          w={"100%"}
          p={{ md: 6, base: 3 }}
          bg="b10"
          border={borders.blackNormal}
          justifyContent={"flex-start"}
          flexDirection={{ md: "row", base: "column" }}
          alignItems={{ md: "center", base: "flex-start" }}
          fontWeight={"semibold"}
        >
          <Text fontSize={{ md: "xl", base: "sm" }}>
            {isDuringRound ? (
              "Hurry! Jackpot drains in"
            ) : isDuringGapTime ? (
              "Next round starts in"
            ) : (
              <Text
                fontSize={{ md: "xl", base: "sm" }}
                fontWeight={"semibold"}
                fontFamily="Poppins"
              >
                {isWaitingWinner ? (
                  <>
                    Be the first to win big! <br /> Unlock up to{" "}
                    <Text as="span" fontWeight={900}>
                      10x
                    </Text>{" "}
                    returns with your keys now!
                  </>
                ) : (
                  <>
                    Claim your rewards or buy keys to
                    <br /> kick off the next round!
                  </>
                )}
              </Text>
            )}
          </Text>
          {(isDuringRound || isDuringGapTime) && (
            <CommonCountdown
              fontFamily={"DM Mono"}
              fontSize={{ md: "2xl", base: "24px" }}
              fontWeight={"medium"}
              hasSpace={false}
            />
          )}
        </Center>
      </Box>
      <Box w="100%">
        <Center
          bg="black"
          h={"1.75rem"}
          fontSize={{ md: "md", base: "xs" }}
          color="white"
          w="max-content"
          px={6}
        >
          Dashboard
        </Center>
        <VStack
          bg="b10"
          border={borders.blackNormal}
          w={"100%"}
          p={{ md: 6, base: "10px" }}
          spacing={{ md: 8, base: 3 }}
        >
          <InfoItem
            title={"Jackpot"}
            num={formatAmount(curPot as BigNumber, tokenDecimal, 4, 0, true)}
            // value={numberWithCommas(
            //   +(
            //     ((curPot
            //       ? formatAmount(curPot as BigNumber, 18, 6, 0, false, true)
            //       : 0) as number) * tokenPrice
            //   ).toFixed(2)
            // )}
            // token={memeTokenInfo?.symbol}
          />
          <InfoItem
            title={"Your Keys"}
            num={
              keysOwned && !isDuringGapTime
                ? (+formatAmount(keysOwned, 18, 2) as number).toFixed(0)
                : 0
            }
            value={
              roundTotalKeys && !isDuringGapTime
                ? (+formatAmount(roundTotalKeys, 18, 2) as number).toFixed(0)
                : 0
            }
            totalText="Total"
            token={
              <Icon boxSize={6} viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" rx="4" fill="black" />
                <path
                  d="M13.3016 12.3C13.8016 13.1 14.0016 14 14.0016 15C14.0016 17.8 11.8016 20 9.00156 20C6.20156 20 4.00156 17.8 4.00156 15C4.00156 12.2 6.20156 10 9.00156 10C10.1016 10 11.0016 10.3 11.9016 10.9L14.2016 8.6L13.1016 7.5L14.5016 6.1L15.6016 7.2L16.3016 6.5L15.2016 5.4L16.6016 4L19.1016 6.5L13.3016 12.3ZM9.00156 18C10.7016 18 12.0016 16.7 12.0016 15C12.0016 13.3 10.7016 12 9.00156 12C7.30156 12 6.00156 13.3 6.00156 15C6.00156 16.7 7.40156 18 9.00156 18Z"
                  fill="white"
                />
              </Icon>
            }
            symbol="Keys"
          />
          <InfoItem
            title={"Your Earnings"}
            num={formatAmount(total || 0, tokenDecimal, 4, 0, true)}
            // value={getUSDPrice(total, tokenPrice)}
            token={memeTokenInfo?.symbol}
          />
        </VStack>
      </Box>
    </VStack>
  );
};

export default RoundTabPanel;
