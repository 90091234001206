import { Center, Flex, Image, Text, VStack } from "@chakra-ui/react";
import CustomModal from "components/CustomModal";
import prizePoolImg from "assets/prize-pool.png";
import { useEffect } from "react";
import { formatAmount } from "utils/numbers";
import BigNumber from "bignumber.js";

const NewRoundModal = ({
  isOpen,
  onClose,
  curRoundNum,
  memeTokenInfo,
  curPot,
  tokenDecimal,
}) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [isOpen, onClose]);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} title="NEW ROUND">
      <VStack spacing={2} px={6} pb={{ lg: 6, base: 0 }}>
        <Text
          fontSize={{ lg: "40px", base: "24px" }}
          fontFamily={"Rubik One"}
          color="brand"
        >
          Round #{curRoundNum}
        </Text>
        {/* <Flex gap={4}>
          <Text
            color="brand"
            fontSize={{ lg: "xl", base: "xs" }}
            fontFamily={"Rubik Mono One"}
          >
            {isDuringRound ? "WILL END IN" : "WILL BEGIN IN"}
          </Text>
          <CommonCountdown
            fontFamily={"DM Mono"}
            fontSize={{ lg: "xl", base: "xs" }}
            fontWeight={"medium"}
            color="white"
          />
        </Flex> */}
        <Center
          position={"relative"}
          w={"40rem"}
          color="white"
          h={"8.5rem"}
          mt={{ lg: "2.5rem", base: 8 }}
        >
          <Image
            src={prizePoolImg}
            w={{ lg: "40rem", base: "24rem" }}
            maxW="100%"
            position={"absolute"}
            zIndex={-1}
          />
          <Flex
            flexWrap={"wrap"}
            maxW={{ lg: "360px", base: "200px" }}
            gap={2}
            justifyContent={"center"}
            rowGap={0}
            alignItems={"center"}
            mt={{ lg: 8, base: 6 }}
            fontSize={{ lg: "2xl", base: "md" }}
          >
            <Text fontWeight={"bold"}>
              {curPot
                ? formatAmount(curPot as BigNumber, tokenDecimal, 4, 0, true)
                : 0}
            </Text>{" "}
            <Text fontWeight={"medium"}>{memeTokenInfo?.symbol}</Text>
          </Flex>
        </Center>
      </VStack>
    </CustomModal>
  );
};

export default NewRoundModal;
