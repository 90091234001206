import { bsc, bscTestnet } from "viem/chains";

const CONTRACTS = {
  [bscTestnet.id]: {
    fomome: {
      tfb: "0x247eaf2aee5baa97cba275258dede39036ba8e21",
      busd: "0x2824B6CE4fa888d57fAA218BB7f82DBe814d7a21",
      babydoge: "0x43822450544BF9c568f74F919Ec08B2E928024Ed",
    },
    playBook: "0x42169576bB35a65e64bc9E402d16e16032923023",
    staking: "0xaF357f263792c16C09b84ED6BA6270f1eD075D52",
    wBNB: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    bnbOracle: "0x1A26d803C2e796601794f8C5609549643832702C",

    fomomeV3Pool: "0x1288b13CcD66fdA3DdF323F89A1FBE5a82285a50",
    whyV3Pool: "0x1288b13CcD66fdA3DdF323F89A1FBE5a82285a50",
    babydogeV3Pool: "0x1288b13CcD66fdA3DdF323F89A1FBE5a82285a50",
  },
  [bsc.id]: {
    fomome: {
      exo: "0xb5638E9B47755a67811cD4bB38eb59FEfD9C177c",
      why: "",
      babydoge: "0x2fFfEd4Ce21beb5fc9Eefb12E6038c3A7b39a745",
    },
    playBook: "0x62c443a024fbE658459351a10e3a7898738bd574",
    staking: "0x6D286d2ea9d78b99997b62448e5DeB0276Fbda93",
    wBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    bnbOracle: "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE",

    fomomeV3Pool: "0x95C4C2a218c6658CaFe5DE1DF9088cd18297aa37",
    whyV3Pool: "0x6cd061334aafee6cdd5bc5c958e71f769d7ca8cc",
    babydogeV3Pool: "0x61db764C20a2EBfB7e8a7a5AFb0b2Dd85A4CEF5f",
  },
};

export function getContract(chainId: number, name: string): `0x${string}` {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (CONTRACTS[chainId][name] === undefined) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}

export const DEFAULT_CHAIN_ID = bscTestnet.id;

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
