import { Box, Button, Center, Flex, Icon, Text } from "@chakra-ui/react";
import { borders } from "theme";
import { formatAmount } from "utils/numbers";
import { getUSDPrice } from "utils/util";

const VaultPanel = ({
  memeTokenInfo,
  vaultInfo,
  tokenPrice,
  onWithdraw,
  tokenDecimal,
}) => {
  return (
    <Box w="100%">
      <Center
        bg="black"
        h={{ md: "1.75rem", base: 6 }}
        fontSize={{ md: "md", base: "xs" }}
        color="white"
        px={{ md: 6, base: 3 }}
        w="max-content"
      >
        Vault
      </Center>
      <Flex
        direction={"column"}
        gap={5}
        border={borders.blackNormal}
        bg="b10"
        p={{ md: 6, base: 2 }}
      >
        <InfoItem
          title={"Rewards"}
          value={formatAmount(vaultInfo.atExit, tokenDecimal, 4, 0, true)}
          memeTokenInfo={memeTokenInfo}
        />
        <InfoItem
          title={"Dividends"}
          value={formatAmount(vaultInfo.dividends, tokenDecimal, 4, 0, true)}
          memeTokenInfo={memeTokenInfo}
        />
        <InfoItem
          title={"Referrals Bonus"}
          value={formatAmount(vaultInfo.referrals, tokenDecimal, 4, 0, true)}
          memeTokenInfo={memeTokenInfo}
        />
        <Box
          w="100%"
          h="1px"
          bg="black"
          mt={{ md: 1, base: 0 }}
          mb={{ md: 3, base: 0 }}
        ></Box>
        <Box>
          <InfoItem
            title={"Total Gains"}
            value={formatAmount(
              vaultInfo?.total || 0,
              tokenDecimal,
              4,
              0,
              true
            )}
            memeTokenInfo={memeTokenInfo}
          />
          {/* TODO: usd */}
          {/* <Flex
            gap={{ md: 2, base: 0 }}
            fontSize={{ md: "md", base: "xs" }}
            justifyContent={"flex-end"}
          >
            ≈
            <Text fontWeight={"bold"}>
              {getUSDPrice(vaultInfo?.total, tokenPrice)}
            </Text>
            <Text fontWeight={"medium"}>USD</Text>
          </Flex> */}
        </Box>
      </Flex>
      <Button
        variant={"normal"}
        w="100%"
        mt={{ md: 6, base: 3 }}
        fontSize={{ md: "2xl", base: "md" }}
        fontWeight={"bold"}
        onClick={onWithdraw}
      >
        Withdraw
        <Icon
          width={{ md: "28px", base: "20px" }}
          height={{ md: "28px", base: "20px" }}
          viewBox="0 0 28 27"
          fill="none"
          ml={"10px"}
        >
          <g clipPath="url(#clip0_10122_2881)">
            <mask
              id="mask0_10122_2881"
              maskUnits="userSpaceOnUse"
              x="2"
              y="2"
              width="25"
              height="24"
            >
              <rect x="2.5" y="2" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_10122_2881)">
              <path
                d="M6.75469 22.2863C5.99754 22.2863 5.35826 22.0256 4.83683 21.5041C4.3154 20.9827 4.05469 20.3434 4.05469 19.5863V11.6148C4.05469 10.8577 4.3154 10.2184 4.83683 9.69699C5.35826 9.17556 5.99754 8.91484 6.75469 8.91484H8.57612V11.6148H6.75469V19.5863H18.2404V11.6148H16.419V8.91484H18.2404C18.9975 8.91484 19.6368 9.17556 20.1583 9.69699C20.6797 10.2184 20.9404 10.8577 20.9404 11.6148V19.5863C20.9404 20.3434 20.6797 20.9827 20.1583 21.5041C19.6368 22.0256 18.9975 22.2863 18.2404 22.2863H6.75469ZM11.1475 16.1791V6.8577L9.77612 8.22913L7.8904 6.34342L12.4975 1.71484L17.1047 6.34342L15.219 8.22913L13.8475 6.8577V16.1791H11.1475Z"
                fill="currentColor"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_10122_2881">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </Icon>
      </Button>
    </Box>
  );
};

const InfoItem = ({ title, value, memeTokenInfo }) => {
  return (
    <Flex
      w="100%"
      justifyContent={"space-between"}
      fontSize={{ md: "xl", base: "sm" }}
      fontWeight={"medium"}
    >
      <Text>{title}</Text>
      <Flex w="50%" justifyContent={"flex-end"} gap={2}>
        <Text fontWeight={"bold"}>{value || "0"}</Text>
        <Text>{memeTokenInfo?.symbol}</Text>
      </Flex>
    </Flex>
  );
};

export default VaultPanel;
