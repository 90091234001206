import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import moneyImg from "assets/money.png";
import CustomModal from "components/CustomModal";
import { useIsMobileView } from "hooks/usePageSize";
import { useEffect, useState } from "react";
import { useCopyToClipboard } from "react-use";
import { borders } from "theme";
import { useAccount, useReadContracts, useWriteContract } from "wagmi";
import playbookContractAbi from "abis/abi-playbook.json";
import { getContract } from "config/contracts";
import { formatAmount } from "utils/numbers";
import TokenAbi from "abis/token.json";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { setParamToUrl } from "utils/util";

const RefferalsPanel = ({ address, chainId, name, id, memeTokenInfo }) => {
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const isMobile = useIsMobileView();
  const [isOpenReferralNameModal, setIsOpenReferralNameModal] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();
  const [isNameValid, setIsNameValid] = useState(true);
  const [feeValue, setFeeValue] = useState<BigInt>();

  const referralsInfo = [
    {
      title: "Wallet Referrals",
      link: setParamToUrl([
        { key: "ref", val: `${address ?? "undefined"}` },
        { key: "type", val: "wallet" },
      ]),
    },
    {
      title: "Anonymous Referrals",
      link: setParamToUrl([
        { key: "ref", val: `${id || "undefined"}` },
        { key: "type", val: "id" },
      ]),
    },
    {
      title: "Referrals",
      link: setParamToUrl([
        { key: "ref", val: `${name || "undefined"}` },
        { key: "type", val: "name" },
      ]),
    },
  ];

  const nativeTokenAddr = memeTokenInfo?.nativeTokenAddr;

  const playbookContractInfo = {
    address: getContract(chainId, "playBook"),
    abi: playbookContractAbi,
    chainId: chainId,
  };

  const { data } = useReadContracts({
    contracts: [
      {
        ...playbookContractInfo,
        functionName: "checkIfNameValid",
        args: [inputVal],
      },
      {
        ...playbookContractInfo,
        functionName: "registrationFee_",
      },
      {
        abi: TokenAbi,
        address: nativeTokenAddr,
        functionName: "allowence",
      },
    ],
    query: {
      enabled: !!address,
      refetchInterval: 1000,
    },
  });

  useEffect(() => {
    if (!data) return;
    setIsNameValid(!!data?.[0]?.result);
    setFeeValue(data?.[1]?.result as BigInt);
  }, [data]);

  const { writeContract: registerContract } = useWriteContract({
    mutation: {
      onSettled: async (data, error) => {
        if (error) {
          if (error.message.includes("reject")) {
            return toast({ title: "Wallet Rejection", status: "error" });
          }
          return toast({ title: error.message, status: "error" });
        }
        toast({
          title: `Successfully Registered!`,
          status: "success",
        });
        setIsOpenReferralNameModal(false);
      },
    },
  });

  const handleRegister = () => {
    registerContract({
      ...playbookContractInfo,
      functionName: "registerNameXaddr",
      args: [inputVal, address, true],
      value: feeValue as bigint,
    });
  };

  return (
    <>
      <VStack
        py={{ md: 6, base: "10px" }}
        spacing={{ md: 6, base: 2 }}
        w="100%"
      >
        <Flex
          direction={{ md: "row", base: "column" }}
          justifyContent={{ md: "space-between" }}
          alignItems={{ md: "center", base: "flex-start" }}
          gap={{ base: "16px" }}
          w="100%"
        >
          <Flex gap={2}>
            <Image src={moneyImg} boxSize={10} />
            <Text
              fontSize={{ md: "md", base: "sm" }}
              fontWeight={"medium"}
              maxW={{ md: "17rem" }}
            >
              Invite others and{" "}
              <Text
                as="span"
                textDecoration={"underline"}
                color="green0"
                fontWeight={"bold"}
              >
                earn 10%
              </Text>{" "}
              of everything they spend!
            </Text>
          </Flex>
          {name ? (
            <Center
              bg="b10"
              h={{ md: 10 }}
              px={4}
              w={{ md: "unset", base: "100%" }}
              minW={{ md: "240px" }}
            >
              <Text
                maxW="100%"
                color="b07"
                fontSize={`${name}`.length > 22 ? "10px" : "16px"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow="hidden"
              >
                {name}
              </Text>
            </Center>
          ) : (
            <Button
              variant={"normal"}
              onClick={() => {
                isConnected
                  ? setIsOpenReferralNameModal(true)
                  : openConnectModal?.();
              }}
              h={10}
              fontSize={"md"}
              w={{ md: "unset", base: "100%" }}
              minW={{ md: "240px" }}
            >
              <Icon
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                mr="10px"
              >
                <mask
                  id="mask0_10049_4753"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_10049_4753)">
                  <path
                    d="M2 20L2 17.2C2 16.65 2.14167 16.1333 2.425 15.65C2.70833 15.1667 3.1 14.8 3.6 14.55C4.45 14.1167 5.40833 13.75 6.475 13.45C7.54167 13.15 8.71667 13 10 13C10.5 13 10.9875 13.025 11.4625 13.075C11.9375 13.125 12.4 13.2 12.85 13.3L11.1 15.05C10.9167 15.0167 10.7375 15 10.5625 15L10 15C8.81667 15 7.75417 15.1417 6.8125 15.425C5.87083 15.7083 5.1 16.0167 4.5 16.35C4.35 16.4333 4.22917 16.55 4.1375 16.7C4.04583 16.85 4 17.0167 4 17.2L4 18L10.25 18L12.25 20L2 20ZM15.55 20.4L12.1 16.95L13.5 15.55L15.55 17.6L20.6 12.55L22 13.95L15.55 20.4ZM10 12C8.9 12 7.95833 11.6083 7.175 10.825C6.39167 10.0417 6 9.1 6 8C6 6.9 6.39167 5.95833 7.175 5.175C7.95833 4.39167 8.9 4 10 4C11.1 4 12.0417 4.39167 12.825 5.175C13.6083 5.95833 14 6.9 14 8C14 9.1 13.6083 10.0417 12.825 10.825C12.0417 11.6083 11.1 12 10 12ZM10 10C10.55 10 11.0208 9.80417 11.4125 9.4125C11.8042 9.02083 12 8.55 12 8C12 7.45 11.8042 6.97917 11.4125 6.5875C11.0208 6.19583 10.55 6 10 6C9.45 6 8.97917 6.19583 8.5875 6.5875C8.19583 6.97917 8 7.45 8 8C8 8.55 8.19583 9.02083 8.5875 9.4125C8.97917 9.80417 9.45 10 10 10Z"
                    fill="currentColor"
                  />
                </g>
              </Icon>
              Register Referral name
            </Button>
          )}
        </Flex>
        {!isMobile && <Box h="1px" w="100%" bg="black"></Box>}
        {referralsInfo.map(({ title, link }, index) => {
          return (
            <Flex
              w="100%"
              justifyContent={"space-between"}
              fontWeight={"medium"}
              flexDirection={{ md: "row", base: "column" }}
              mt={{ md: 0, base: index ? 0 : 4 }}
              key={index}
            >
              <Text fontSize={{ md: "xl", base: "sm" }}>{title}</Text>
              <Flex h={10} fontSize={"md"} w={{ md: "unset", base: "100%" }}>
                <Center
                  bg="white"
                  border={borders.blackNormal}
                  h="100%"
                  w={{ md: 40, base: "57%" }}
                  px={3}
                  color="b08"
                >
                  <Text
                    w="100%"
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    textOverflow={"clip"}
                  >
                    {link}
                  </Text>
                </Center>
                <Center
                  bg="black"
                  color="white"
                  h="100%"
                  w={{ md: "7.5rem", base: "43%" }}
                  cursor="pointer"
                  _hover={{ color: "brand" }}
                  onClick={() => {
                    copyToClipboard(link);
                    toast({
                      title: "Copied Successfully!",
                      status: "success",
                    });
                  }}
                >
                  Copy Link
                </Center>
              </Flex>
            </Flex>
          );
        })}
      </VStack>
      <CustomModal
        isOpen={isOpenReferralNameModal}
        onClose={() => setIsOpenReferralNameModal(false)}
        title="Register Referral name"
      >
        <Input
          w="100%"
          h={10}
          bg="white"
          _placeholder={{ color: "b08", fontWeight: "medium", fontSize: "md" }}
          placeholder="Random Name"
          borderRadius={0}
          value={inputVal}
          onChange={(ev) => {
            setInputVal(ev.target?.value);
          }}
        />
        {!isNameValid && inputVal && (
          <Text color="red0" fontSize={"sm"} fontWeight={"medium"}>
            This name is inValid, please choose another one
          </Text>
        )}
        <Text fontSize={"xs"} color="white" maxW={"39rem"} mt={6}>
          Names must follow these rules:
          <br />
          · Must be unique <br />
          · 32 Characters or less
          <br />
          · A-Z(upper and lowercase)
          <br />
          · No special characters <br />
          · No more than one space between characters
          <br />
          <br />
          If a transaction fails, it means one of the criteria wasn’t met. Names
          are yours permanently (for referral URLs), but only your most recent
          name will appear on the leaderboard and game UI. Plus, you can collect
          as many names as you like!
        </Text>
        <Button
          variant={"brand"}
          w="100%"
          mt={6}
          onClick={handleRegister}
          isDisabled={!isNameValid || !inputVal}
        >
          Purchase For {+formatAmount(Number(feeValue || 0), 18, 2, "0.01")} BNB
        </Button>
        <Text fontSize={"xs"} color={"white"} mt={2}>
          The fee is shared among the community members who made this game
          possible.
        </Text>
      </CustomModal>
    </>
  );
};

export default RefferalsPanel;
