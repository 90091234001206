import { Box, Flex, Input, InputProps, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const NumberInput = ({
  inputFiledProps,
  value,
  onChange,
  decimals = 30,
  integral = 30,
  minVal,
  maxVal,
  ...rest
}: {
  inputFiledProps: any;
  value: string;
  onChange: (val: string) => void;
  decimals: number;
  integral: number;
  minVal?: number;
  maxVal?: number;
} & InputProps) => {
  const [val, setVal] = useState("");
  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = ({ target: { value: v } }: any) => {
    let _v = v;
    if (!_v) {
      setVal(_v);
      onChange?.(_v);
      return;
    }

    _v = _v
      .replace(/。/g, ".")
      .replace(/[^\d.]/g, "")
      .replace(/^\./, "");

    let foundDot = false;
    _v = _v
      .split("")
      .filter((char: string) => {
        if (char === ".") {
          if (foundDot) {
            return false;
          }
          foundDot = true;
        }
        return true;
      })
      .join("");

    if (/^0\d$/.test(v)) {
      _v = v.slice(1);
    }

    _v = _v.replace(
      new RegExp(`^(\\d{1,${integral}})(\\.\\d{0,${decimals}})?.*$`),
      "$1$2"
    );

    if (+_v && minVal !== undefined && +_v < minVal) _v = `${minVal}`;
    if (+_v && maxVal !== undefined && +_v > maxVal) _v = `${maxVal}`;

    setVal(_v);
    onChange?.(_v);
  };

  return (
    <Input
      min={0}
      variant={"unstyled"}
      paddingRight={0}
      fontSize={"2xl"}
      fontWeight={"medium"}
      lineHeight={"180%"}
      verticalAlign={"middle"}
      textAlign={"left"}
      sx={{
        caretColor: "black",
      }}
      {...rest}
      {...inputFiledProps}
      value={val}
      onChange={handleChange}
    />
  );
};

export const CustomInput = ({
  inputProps,
  topContent,
  disabled,
  leftContent,
  usdValue = 0,
  minVal,
  maxVal,
  ...rest
}: any) => {
  return (
    <Box borderRadius={"4px"} {...rest}>
      {topContent}
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        fontSize={"md"}
        gap={2}
        height="100%"
      >
        <Box flexShrink={0}>{leftContent}</Box>
        <Box flex={1} color={"black"} position={"relative"}>
          <NumberInput
            inputFiledProps={{
              // fontSize: "40px",
              // lineHeight: "normal",
              // fontWeight: 400,
              // color: "black",
              // fontFamily: "Rubik Mono One",
              ...rest,
            }}
            disabled={disabled}
            minVal={minVal}
            maxVal={maxVal}
            {...inputProps}
          />
        </Box>
      </Flex>
      {!!usdValue && (
        <Text fontSize={"md"} color="b05" w="100%" mt={1} textAlign={"right"}>
          ${usdValue}
        </Text>
      )}
    </Box>
  );
};

export function MaxButton({ onClick, ...rest }: any) {
  return (
    <Text
      color={"brand"}
      fontSize={"sm"}
      onClick={onClick}
      _hover={{ color: "y05" }}
      cursor={"pointer"}
      {...rest}
    >
      Max
    </Text>
  );
}
