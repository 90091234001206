import { useReadContracts } from "wagmi";
import tokenPairAbi from "abis/token-pair-v3.json";
import tokenPair2Abi from "abis/bnb_usdt.json";
import { useEffect, useState } from "react";
import { getContract } from "config/contracts";
import { getMemeTokenInfo } from "config/tokens";
import BigNumber from "bignumber.js";

export function formatPrice(price: number, significantFigures: number = 4): string {
  if (price === 0) return '0';

  const absPrice = Math.abs(price);
  
  if (absPrice < 1e-12) {
    // For very small numbers, use scientific notation
    return price.toExponential(significantFigures - 1);
  } else if (absPrice < 1) {
    // For numbers less than 1, count leading zeros after decimal point
    const leadingZeros = Math.floor(Math.log10(1 / absPrice));
    return price.toFixed(leadingZeros + significantFigures - 1);
  } else {
    // For numbers >= 1, use fixed decimal places
    return price.toPrecision(significantFigures);
  }
}

export const useTokenPrices = (chainId: number, symbols: string[]) => {
  let defaultPrices = {};
  for (const symbol of symbols) {
    defaultPrices[symbol.toLowerCase()] = 0.00003242;
  }

  const [tokenPrices, setTokenPrices] = useState<Record<string, number>>(defaultPrices);
  const wBNBAddr = getContract(chainId, "wBNB");
  const bnbOracleAddr = getContract(chainId, "bnbOracle");

  const contracts = symbols.flatMap(symbol => {
    const tokenInfo = getMemeTokenInfo(chainId, symbol);
    const bnbV3PoolAddr = tokenInfo?.bnbV3PoolAddr;
    if (!bnbV3PoolAddr) return [];

    const v3PoolInfo = {
      abi: tokenPairAbi,
      address: bnbV3PoolAddr,
    };

    return [
      { ...v3PoolInfo, functionName: "slot0" },
      { ...v3PoolInfo, functionName: "token0" },
      { ...v3PoolInfo, functionName: "token1" },
    ];
  });

  contracts.push({
    abi: tokenPair2Abi,
    address: bnbOracleAddr,
    functionName: "latestAnswer",
  });

  const { data } = useReadContracts({
    contracts,
    query: {
      enabled: contracts.length > 0 && !!bnbOracleAddr,
    },
  });

  useEffect(() => {
    if (!data) return;

    const bnbOraclePrice = data[data.length - 1].result as BigNumber;
    const bnbPrice = BigNumber(bnbOraclePrice).div(10 ** 8);

    const newPrices: Record<string, number> = {};

    symbols.forEach((symbol, index) => {
      const tokenInfo = getMemeTokenInfo(chainId, symbol);
      const baseIndex = index * 3;
      const [slot0Res, token0Res, token1Res] = data.slice(baseIndex, baseIndex + 3);

      if (!(slot0Res?.result && token0Res?.result && token1Res?.result)) {
        console.error(`Missing data for ${symbol}`);
        newPrices[symbol.toLowerCase()] = 0.00003242;
        return;
      };

      const [sqrtPriceX96] = (slot0Res.result as any[]) || [];
      const token0Address = token0Res.result as string;
      const token1Address = token1Res.result as string;

      const sqrtPrice = BigNumber(sqrtPriceX96).div(2 ** 96);
      const price = sqrtPrice.pow(2);
      
      let tokenPriceInBNB;
      if (token0Address.toLowerCase() === wBNBAddr.toLowerCase()) {
        tokenPriceInBNB = price.div(10**tokenInfo.decimal / 10**18);
        tokenPriceInBNB = BigNumber(1).div(tokenPriceInBNB);
      } else if (token1Address.toLowerCase() === wBNBAddr.toLowerCase()) {
        tokenPriceInBNB = price.div(10**18 / 10**tokenInfo.decimal);
      } else {
        console.error(`BNB is neither token0 nor token1 for ${symbol}`);
        tokenPriceInBNB = BigNumber(0);
      }

      const tokenPrice = tokenPriceInBNB.multipliedBy(bnbPrice);
      newPrices[symbol.toLowerCase()] = tokenPrice.gt(0) ? tokenPrice.toNumber() : 0.00003242;
    });

    setTokenPrices(newPrices);
  }, [data]);

  return tokenPrices;
};
