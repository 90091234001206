import { useToast } from "@chakra-ui/react";
import { useWriteContract } from "wagmi";

const useCustomWriteContract = (successText = "Success!", cb?) => {
  const toast = useToast();

  const { writeContract } = useWriteContract({
    mutation: {
      onSettled: async (data, error) => {
        cb?.(!error);
        if (error) {
          if (error.message.includes("reject")) {
            return toast({ title: "Wallet Rejection", status: "error" });
          }
          if (error.message.includes("0xe450d38c")) {
            return toast({
              title: "Insufficient token balance",
              status: "error",
            });
          }
          if (
            error.message.includes(
              "Arithmetic operation resulted in underflow or overflow"
            )
          ) {
            return toast({
              title: "Don't buy too many Keys in the early game",
              status: "error",
            });
          }
          return toast({ title: error.message, status: "error" });
        }
        toast({
          title: successText || `Success!`,
          status: "success",
        });
      },
    },
  });

  return writeContract;
};

export default useCustomWriteContract;
