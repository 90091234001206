import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/900.css";
import "@fontsource/dm-mono/400.css";
import "@fontsource/dm-mono/500.css";
import "@fontsource/rubik-mono-one/400.css";
import "@fontsource/rubik-one/400.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/400.css";

export const colors = {
  brand: "#FCD535",
  lightYellow: "rgba(226, 255, 51, 0.05)",
  babyBlue: "#B0D4E9",
  b02: "#191919",
  b03: "#333333",
  b04: "#4D4D4D",
  b05: "#666666",
  b06: "#808080",
  b07: "#999999",
  b08: "#B3B3B3",
  b09: "#cccccc",
  b10: "#E5E5E5",
  green0: "#01C86A",
  red0: "#E81762",
};

export const borders = {
  brandNormal: `1px solid ${colors.brand}`,
  thinBrand: `0.5px solid ${colors.brand}`,
  thickBrand: `2px solid ${colors.brand}`,
  blackNormal: `1px solid #000;`,
  blackThick: `2px solid #000;`,
  whiteThick: `2px solid #fff;`,
};

const theme = {
  styles: {
    global: {
      html: {
        fontSize: "md",
      },
      body: {
        bg: "linear-gradient(0deg, #000 0%, #000 100%)",
        color: "black",
        fontFamily: "Poppins",
      },
      background: "black",
    },
  },
  colors,
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
      variants: {
        normal: {
          bg: "black",
          h: { md: "3.75rem", base: 10 },
          color: "white",
          fontWeight: "medium",
          fontSize: { md: "lg", base: "sm" },
          px: 4,
          borderRadius: "0px",
          _hover: {
            color: "brand",
          },
          _active: {
            color: "brand",
          },
        },
        brand: {
          bg: "brand",
          h: { lg: "3rem", base: 10 },
          color: "black",
          fontWeight: "bold",
          fontSize: { lg: "xl", base: "md" },
          border: borders.blackNormal,
          px: 4,
          borderRadius: "0px",
          _hover: {
            _disabled: {
              background: "#333",
              color: "b05",
            },
            bg: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #FFCD00",
          },
          _disabled: {
            border: "1px solid #666",
            background: "#333",
            color: "b05",
          },
        },
        plain: {
          bg: "white",
          h: "3.75rem",
          color: "black",
          fontWeight: "medium",
          fontSize: "lg",
          px: 4,
          borderRadius: "0px",
          border: borders.blackNormal,
        },
        outline: {
          bg: "transparent",
          h: 10,
          color: "brand",
          fontWeight: "medium",
          fontSize: "xs",
          px: 3,
          borderRadius: "2px",
          border: borders.brandNormal,
          borderColor: "brand",
          _hover: {
            bg: "rgba(252, 213, 53, 0.10);",
          },
          _active: {
            bg: "rgba(252, 213, 53, 0.10);",
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
